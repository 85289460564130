import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import img1 from '../../assets/landing/s3.jpg'; // Ensure the path to the image is correct

const JobCard = () => {
  return (
    <Box sx={{ maxWidth: 1000, mx: 'auto', p: 2 }}>
      <Grid container spacing={25} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={img1}
            alt="Hospitality"
            sx={{ width: '120%', borderRadius: 1 }}
          />
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
          Transportation & Logistics
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
          Roles like drivers (truck, van, delivery), bike riders, and logistics coordinators. This industry is key for ensuring goods and services move efficiently across borders and regions.
          </Typography>
          {/* <Button
            variant="contained"
            color="warning" // Adjust the color to match the design
            sx={{ borderRadius: 1, textTransform: 'none' }}
          >
            Apply Now
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobCard;
