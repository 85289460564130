import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link, NavLink, useNavigate } from 'react-router-dom';

import Logo from '../../assets/Logo/skylog201.png';
import { Window } from '@mui/icons-material';

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleContactClick = (event) => {
    event.preventDefault();
    handleDrawerToggle(false);
    // navigate('/#contact-section'); // Navigate to the Contact Us section
    document.getElementById('contact-section')?.scrollIntoView({ behavior: 'smooth' });
    
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#000000', height: '100%' }}>
      <img src={Logo} alt="Logo" style={{ height: '75px', margin: '16px 0' }} />
      <Divider sx={{ bgcolor: '#ffffff' }} />
      <List>
      <ListItem key={''} disablePadding>
            <ListItemButton
              component={NavLink}
              to={`/`}
              sx={{ textAlign: 'center', color: '#ffffff' }}
            >
              <ListItemText primary={"Home"} />
            </ListItemButton>
      </ListItem>
         
      <ListItem key={''} disablePadding>
            <ListItemButton
              component={NavLink}
              to={`/our-jobs`}
              sx={{ textAlign: 'center', color: '#ffffff' }}
            >
              <ListItemText primary={"Our Jobs"} />
            </ListItemButton>
      </ListItem>
                  
      <ListItem key={''} disablePadding>
            <ListItemButton
              component={NavLink}
              to={`/industries`}
              sx={{ textAlign: 'center', color: '#ffffff' }}
            >
              <ListItemText primary={"Industries"} />
            </ListItemButton>
      </ListItem>

      <ListItem key={''} disablePadding>
            <ListItemButton
              component={NavLink}
              to={`/about`}
              sx={{ textAlign: 'center', color: '#ffffff' }}
            >
              <ListItemText primary={"About"} />
            </ListItemButton>
      </ListItem>
          
      {/* <ListItem key={''} disablePadding>
            <ListItemButton onClick={handleContactClick}
              component={NavLink}
             
              sx={{ textAlign: 'center', color: '#ffffff' }}
            >
              <ListItemText primary={"Contact Us"} />
            </ListItemButton>
      </ListItem> */}

      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ position: 'relative' }} elevation={0}>
        <Toolbar sx={{ height: '100px', backgroundColor: '#ffffff', position: 'relative' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: '#000000' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            <img src={Logo} alt="Logo" style={{ height: '75px' }} />
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: '700',
                color: '#ffb84f',
                "&:hover": {
                  color: '#000000',
                },
                marginLeft: '8px',
              }}
            >
              Sky Europe
            </Typography>
          </Box>

          <Box sx={{ display: { xs: 'none', sm: 'block' }, ml: 'auto' }}>
            <Button component={NavLink}  to="/" sx={{ color: '#000000', fontSize: '18px', textTransform: 'capitalize' }}>
              Home 
            </Button>
            <Button component={NavLink} to="/our-jobs" sx={{ color: '#000000', fontSize: '18px', textTransform: 'capitalize' }}>
              Our Jobs
            </Button>
            <Button component={NavLink} to="/industries" sx={{ color: '#000000', fontSize: '18px', textTransform: 'capitalize' }}>
              Industries
            </Button>
            <Button component={NavLink} to="/about" sx={{ color: '#000000', fontSize: '18px', textTransform: 'capitalize' }}>
              About
            </Button>
            <Button  onClick={handleContactClick} sx={{ color: '#000000', fontSize: '18px', textTransform: 'capitalize' }}>
              Contact Us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#000000' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
