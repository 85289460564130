import React, { useState } from "react";
import { Container, Grid, TextField, Button, Typography, Box } from "@mui/material";
import { Email, Phone, LocationOn } from "@mui/icons-material";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/send-email", formData);
      alert("Your message has been sent!");
      setFormData({ firstName: "", lastName: "", email: "", message: "" });
    } catch (error) {
      alert("There was an error sending your message. Please directly contact us via email or phone number");
      console.error(error);
    }
  };

  return (
    <Container 
    id="contact-section"
      maxWidth={false} 
      disableGutters
      sx={{ backgroundColor: "#ff6600", padding: { xs: 2, md: 4 }, color: "white", width: "100%" }}
    >
      <Grid container spacing={4} sx={{ maxWidth: "1200px", margin: "0 auto" }}>
        
        {/* Left side with contact info */}
        <Grid item xs={11} md={6}>
          <Typography 
            variant="h3" 
            sx={{ fontWeight: "bold", fontSize: { xs: '1.5rem', md: '2.5rem' } }}
          >
            Get In Touch
            Call us now...
          </Typography>
          <Box display="flex" alignItems="center" mt={4}>
            <LocationOn fontSize="large" />
            <Typography ml={1} fontSize={20} ><strong>No 214, 1st floor, Bandaragama road, Kesbewa</strong></Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Phone fontSize="large" />
            <Typography ml={1} fontSize={20} ><strong> <a style={{textDecoration: 'none', color:'inherit'}} href="tel:+94112702701">0112 - 702 701 </a>/ <a href="tel:+94765508787" style={{textDecoration: 'none', color:'inherit'}} >+94 76 550 8787 </a></strong> </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Email fontSize="large" />
            <Typography ml={1} fontSize={20} ><strong><a style={{textDecoration: 'none', color:'inherit'}} href="mailto:info@skyeuropejobs.com">info@skyeuropejobs.com</a></strong> </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Email fontSize="large" />
            <Typography ml={1} fontSize={20} ><strong><a style={{textDecoration: 'none', color:'inherit'}} href="mailto:skyeuropejobs@gmail.com">skyeuropejobs@gmail.com</a></strong></Typography>
          </Box>
        </Grid>

        {/* Right side with form */}
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              
              {/* Name fields */}
              <Grid item xs={10} sm={6} md={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  variant="filled"
                  required
                  InputProps={{ style: { backgroundColor: "white" } }}
                  sx={{ maxWidth: { xs: '100%', sm: '80%', md: '100%' } }}
                />
              </Grid>
              <Grid item xs={10} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  variant="filled"
                  required
                  InputProps={{ style: { backgroundColor: "white" } }}
                  sx={{ maxWidth: { xs: '100%', sm: '80%', md: '100%' } }}
                />
              </Grid>

              {/* Email field */}
              <Grid item xs={10} md={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="filled"
                  type="email"
                  required
                  InputProps={{ style: { backgroundColor: "white" } }}
                  sx={{ maxWidth: { xs: '100%', sm: '90%', md: '100%' } }}
                />
              </Grid>

              {/* Comment/Message field */}
              <Grid item xs={10} md={12}>
                <TextField
                  fullWidth
                  label="Comment or Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  variant="filled"
                  multiline
                  rows={4}
                  InputProps={{ style: { backgroundColor: "white" } }}
                  sx={{ maxWidth: { xs: '100%', sm: '90%', md: '100%' } }}
                />
              </Grid>

              {/* Submit button */}
              <Grid item xs={10} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    ":hover": { backgroundColor: "#e0e0e0" },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactForm;
