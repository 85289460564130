import React from 'react';
import { Grid } from '@mui/material';
import TopNavBar from '../nav_bar/topNav';
import SideNavBar from '../nav_bar/secondNav';
import Banner1 from  './Banner1';
import Banner2 from  './Banner2';
import Banner3 from  './Banner3';
import Banner4 from  './Banner4';
import Count from  './cont';
import Teams from  './Team';
import Banner5 from  './Banner5';
import Banner6 from  './Banner6';
import MapUs from  './Map';
import Contact from  '../contact-us/contactUs';
import Footer from  '../footer/index';

const Index = () => {
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <TopNavBar />
      </Grid>
      <Grid item xs={12} >
        <SideNavBar />
      </Grid>
      <Grid item xs={12} >
        <Banner1 />
      </Grid>
      <Grid item xs={12} >
        <Banner2 />
      </Grid>
      <Grid item xs={12} >
        <Banner3 />
      </Grid>
      <Grid item xs={12} >
        <Banner4 />
      </Grid>
      <Grid item xs={12} >
        <Count />
      </Grid>
      <Grid item xs={12} >
        <Teams />
      </Grid>
       <Grid item xs={12} >
        <Banner5 />
      </Grid>
      <Grid item xs={12} >
        <Banner6 />
      </Grid>
      <Grid item xs={12} >
        <MapUs />
      </Grid>
      <Grid item xs={12} >
        <Contact />
      </Grid>
      <Grid item xs={12} >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Index;
