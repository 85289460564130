import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import TeamMemberCard from './TeamMemberCard';

import Imag1 from '../../assets/landing/cristina.jpg';
import Imag2 from '../../assets/landing/niroshan.jpg';
import Imag3 from '../../assets/landing/anuradi.jpg';
import Imag4 from '../../assets/landing/nirmani.jpg';
import Imag5 from '../../assets/landing/s1.jpg';
import Imag6 from '../../assets/landing/s2.jpg';
import Imag7 from '../../assets/landing/geethana.jpeg';


const teamMembers = [
  { name: 'Chief Executive Officer', role: 'Cristina Dragomirescu', image: Imag1 },
  { name: 'Managing Director', role: 'Niroshan Gunarathna', image: Imag2 },
  { name: 'Office Manager', role: 'Chamika Perera', image: Imag5 },
  { name: 'Company Secretary', role: 'Anuradhi Fernando', image: Imag3 },
  { name: 'Visa Consultant', role: 'Nirmani Fernando', image: Imag4 },
  { name: 'Visa Consultant', role: 'Akila Dananjaya', image: Imag6 },
  { name: 'Accountant', role: 'Geethana Silva', image: Imag7 },
];

function App() {
  return (
    <Container>
      <Typography
          variant="h1"
          color="#ff6600"
          textAlign="center"
          sx={{ margin: 4 }}
          fontWeight={700}
        >
          Meet Our Team
        </Typography>
        <Typography variant="h6" color="#333" textAlign="center" sx={{ margin: 2 }}>
        Our trustworthy and dedicated team at Sky Europe is committed to providing the best visa consultancy and recruitment services, ensuring clients achieve their dreams seamlessly.
        </Typography>
      <Grid container spacing={5} alignContent={'center'}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={8} md={4} key={index}>
            <TeamMemberCard name={member.name} role={member.role} image={member.image} />
          </Grid> 
        ))}
      </Grid>
    </Container>
  );
}

export default App;
