import React, { useState, useEffect } from 'react';
import { Stack, Box, Button, Fade } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

// Routes
import Routes from './routes/index';

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 200) { // Show button after scrolling down 200px
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  // Function to open WhatsApp in a new tab
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/94765508787", "_blank");
  };

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Router>
      <Box>
        <Stack direction="column" spacing={0}>
          <Routes />
        </Stack>

        {/* WhatsApp Button in Bottom Left */}
        <Button
          variant="contained"
          color="success"
          startIcon={<WhatsAppIcon />}
          onClick={handleWhatsAppClick}
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            zIndex: 1000,
            backgroundColor: '#25D366',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#1ebe5d',
            },
          }}
        >
          Chat with us
        </Button>

        {/* Scroll to Top Button in Bottom Right */}
        <Fade in={showScrollButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={scrollToTop}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: 1000,
              borderRadius:'50%',
              backgroundColor:'#ff6600',
            }}
          >
            <ArrowUpwardIcon />
          </Button>
        </Fade>
      </Box>
    </Router>
  );
}

export default App;
