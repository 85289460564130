import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Email } from '@mui/icons-material';

const TopNavbar = () => {
  return (
    <AppBar elevation={0} position="static" color="default" sx={{ backgroundColor: '#323232', height: 'auto', color: '#ffffff' }}>
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            minHeight: '40px !important',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            padding: { xs: 2, sm: 1 },
          }}
        >
          {/* Address Section */}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mr: { sm: 2 },
              mb: { xs: 1, sm: 0 },
              flexDirection: { xs: 'row', sm: 'row' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <LocationOnIcon sx={{ mr: 0.5, color: '#ffffff', fontSize: 'small' }} />
            <Typography variant="body2" sx={{ color: '#ffffff', fontSize: '0.75rem' }}>
              <a 
                href="https://maps.app.goo.gl/SvGfWWvs6LPmiuVW7" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                No 214, 1st floor, Bandaragama road, Kesbewa
              </a>
            </Typography>
          </Box>

          {/* Email Section */}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mr: { sm: 2 },
              mb: { xs: 1, sm: 0 },
              flexDirection: { xs: 'row', sm: 'row' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <Email sx={{ mr: 0.5, color: '#ffffff', fontSize: 'small' }} />
            <Typography variant="body2" sx={{ color: '#ffffff', fontSize: '0.75rem' }}>
              <a 
                href="mailto:info@skyeuropejobs.com" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#ffffff', textDecoration: 'none' }}
              >
                info@skyeuropejobs.com
              </a>
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mr: { sm: 2 },
              mb: { xs: 1, sm: 0 },
              flexDirection: { xs: 'row', sm: 'row' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <Email sx={{ mr: 0.5, color: '#ffffff', fontSize: 'small' }} />
            <Typography variant="body2" sx={{ color: '#ffffff', fontSize: '0.75rem' }}>
              <a 
                href="mailto:skyeuropejobs@gmail.com" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#ffffff', textDecoration: 'none' }}
              >
                skyeuropejobs@gmail.com
              </a>
            </Typography>
          </Box>

          {/* Contact Number */}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mr: { sm: 2 },
              mb: { xs: 1, sm: 0 },
              flexDirection: { xs: 'row', sm: 'row' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <PhoneIcon sx={{ mr: 0.5, color: '#ffffff', fontSize: 'small' }} />
            <Typography variant="body2" sx={{ color: '#ffffff', fontSize: '0.75rem' }}>
              <a 
                href="tel:+94765508787" 
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                +94 76 550 8787
              </a>
            </Typography>
          </Box>

          {/* Social Media Icons */}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flexDirection: { xs: 'row', sm: 'row' },
              width: { xs: '100%', sm: 'auto' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
            }}
          >
            <IconButton href="https://www.facebook.com/profile.php?id=61554340061376&mibextid=kFxxJD" color="inherit" size="small" sx={{ color: '#ffffff', fontSize: 'small' }}>
              <FacebookIcon fontSize="inherit" />
            </IconButton>
            <IconButton href="https://instagram.com" color="inherit" size="small" sx={{ color: '#ffffff', fontSize: 'small' }}>
              <InstagramIcon fontSize="inherit" />
            </IconButton>
            <IconButton href="https://linkedin.com" color="inherit" size="small" sx={{ color: '#ffffff', fontSize: 'small' }}>
              <LinkedInIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopNavbar;
