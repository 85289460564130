import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import img1 from '../../assets/landing/hospitality-sq-1.webp'; // Ensure the path to the image is correct

const JobCard = () => {
  return (
    <Box sx={{ maxWidth: 1000, mx: 'auto', p: 2 }}>
      <Grid container spacing={25} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={img1}
            alt="Hospitality"
            sx={{ width: '120%', borderRadius: 1 }}
          />
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
            Hospitality
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
            Jobs in hotels, resorts, and restaurants, such as receptionists,
            housekeeping, and catering staff. This industry is perfect for those who enjoy
            customer service and working in dynamic environments.
          </Typography>
          {/* <Button
            variant="contained"
            color="warning" // Adjust the color to match the design
            sx={{ borderRadius: 1, textTransform: 'none' }}
          >
            Apply Now
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobCard;
